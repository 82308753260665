import "owl.carousel"
import moment from "moment/moment";
// import "./instagram"


jQuery(document).ready(function ($) {

  // MUSIC GRID ITEMS
  const covers = [
    { 'link': 'https://vavo.ffm.to/beautiful-as-you-remix', 'img-uri': './dist/img/covers/beautifulasu.jpg', 'alt-text': 'Beautiful As You Remix' },
    { 'link': 'https://KB.lnk.to/MOIRemixes', 'img-uri': './dist/img/covers/milesonyou.jpg', 'alt-text': 'Miles on You Remix' },
    { 'link': 'https://vavo.lnk.to/ComebackKid', 'img-uri': './dist/img/covers/comeback-kid.jpg', 'alt-text': 'Comeback Kid' },
    { 'link': 'https://davidj.lnk.to/AWBURemix', 'img-uri': './dist/img/covers/awbu.jpg', 'alt-text': 'After We Broke Up (Remix) - David J Feat. Frawley' },
    { 'link': 'https://kb.lnk.to/ICFIVAVO', 'img-uri': './dist/img/covers/icanfeelit.jpg', 'alt-text': 'I Can Feel It' },
    { 'link': 'https://ns.lnk.to/WOFremix', 'img-uri': './dist/img/covers/worldonfire.jpg', 'alt-text': 'World On Fire' },
    { 'link': 'https://vavo.ffm.to/this-towns-been-too-good-too-us-remix', 'img-uri': './dist/img/covers/toogood.jpg', 'alt-text': 'This Towns Been Too Good To Us' },
    { 'link': 'https://vavo.ffm.to/one-of-them-girls-remix', 'img-uri': './dist/img/covers/oneofthemgirls.jpg', 'alt-text': 'One Of Them Girls' },
    { 'link': 'https://m10.lnk.to/WGHRemix', 'img-uri': './dist/img/covers/wegothistory.jpg', 'alt-text': 'We Got History' },
    { 'link': 'https://vavo.ffm.to/fast-car-remix', 'img-uri': './dist/img/covers/lukecombs.jpg', 'alt-text': 'Luke Combs - Vavo Remix' },
    { 'link': 'https://strm.to/SpringBreakRemixes', 'img-uri': './dist/img/covers/springbreak23remix.jpg', 'alt-text': 'Spring Break 23 Remixes' },
    { 'link': 'https://hypeddit.com/morganwallen/lastnightvavoxdlayremix', 'img-uri': './dist/img/covers/VAVO-LASTNIGHT.jpg', 'alt-text': 'Last Night' },
    { 'link': 'https://vavo.ffm.to/cowgirls-vavo-moonlght-remix', 'img-uri': './dist/img/covers/cowgirls.jpg', 'alt-text': 'Cowgirls' },
    { 'link': 'https://vavo.ffm.to/thinkin-bout-me-vavoxmoonlight', 'img-uri': './dist/img/covers/mw-thinkin-bout-me.jpg', 'alt-text': 'Thinkin Bout Me' },
    { 'link': 'https://kessrecords.ffm.to/vavo-waitin-on-it', 'img-uri': './dist/img/covers/waitinonit.jpg', 'alt-text': 'Waitin On It' },
    { 'link': 'https://kessrecords.ffm.to/take-me-home-vip', 'img-uri': './dist/img/covers/takemehome.jpg', 'alt-text': 'Take Me Home - VIP Mix' },
    { 'link': 'https://kessrecords.ffm.to/take-me-home', 'img-uri': './dist/img/covers/takemehomeclara.jpg', 'alt-text': 'Take Me Home' },
    { 'link': 'https://vavo.ffm.to/good-times-go-by-too-fast-remix', 'img-uri': './dist/img/covers/gtgbtf.webp', 'alt-text': 'Good Times Go By Too Fast' },
    { 'link': 'https://kessrecords.ffm.to/pieces', 'img-uri': './dist/img/covers/pieces.webp', 'alt-text': 'Pieces' },
    { 'link': 'https://kessrecords.ffm.to/rightnow', 'img-uri': './dist/img/covers/right-now.webp', 'alt-text': 'Right Now' },
  ];

  // MUSIC GRID BUILDER
  function populateGrid(coversArray) {
    $.each(coversArray, function (index, item) {
      let htmlElement = `
            <div class="cover">
              <a href="${item['link']}" target="_blank">
                <img src="${item['img-uri']}" alt="${item['alt-text']}">
              </a>
            </div>
        `;

      $('#grid').append(htmlElement);
    });
  }

  populateGrid(covers);


  // TRANSITION SCROLL TEXT
  var $scrollTextDiv = $('#scrollText');
  var text = '<span class="word">TOUR</span>';
  var repeatCount = 100; // Adjust based on required initial fill

  // Function to animate the scrolling

  function startScrolling($element, duration) {
    $element.animate({ left: -$element.width() }, duration, "linear", function () {
      $element.css({ left: '100%' });
      startScrolling($element, duration); // Loop the animation
    });
  }

  // Initialize the scrolling text
  function initScrolling() {
    // Clear previous content
    $scrollTextDiv.empty();
    var $wordHtml = $(text);

    // Repeat and append text to fill up space adequately depending on the width
    for (var i = 0; i < repeatCount; i++) {
      $scrollTextDiv.append($wordHtml.clone()); // Clone the $wordHtml to append
    }

    // Calculate duration to keep a consistent speed regardless of text length
    var duration = ($scrollTextDiv.width() + $(window).width()) * 10;

    // Start scrolling
    startScrolling($scrollTextDiv, duration);
  }

  // Initial call to set up the scrolling
  initScrolling();

  // Reinitialize scrolling if window is resized
  $(window).resize(function () {
    initScrolling();
  });

  // Additional CSS to add via jQuery for the .word class
  $("<style type='text/css'> .word { margin-right: 10px; } </style>").appendTo("head");
  // Change the 'margin-right' value to increase or decrease spacing




  // Video Carousel
  $('#video-carousel').owlCarousel({
    loop: true,
    nav: true,
    dots: false,
    items: 3,
    startPosition: 0,
    // margin: 20,
    center: true,
    responsive: {
      0: {
        margin: 20,
        items: 1
      },
      700: {
        margin: 20,
        items: 2
      },
      1100: {
        margin: 20,
        items: 3
      }
    },
    navText: [
      '<div class="owl-custom-nav-prev"><i class="far fa-chevron-left"></i></div>',
      '<div class="owl-custom-nav-next"><i class="far fa-chevron-right"></i></div>'
    ]
  })

  // // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_12586754/events?app_id=45PRESS_vavo',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      console.log(data)
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) { 
        for (let event of data) {
          n++
          html += '<div class="event-group">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue-and-location">';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }
    }
  });

  // // Scroll
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();

    var aid = $(this).attr("href");
    $('html,body').animate({ scrollTop: $(aid).offset().top }, 'slow');
  })

  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $("#nav").toggleClass('active')
    $(".nav-item").toggleClass('active')
    $(".line").toggleClass('active')
    if ($('#nav').hasClass('active')) {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo.png" alt="Vavo"></a>')
    } else {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo-black.png" alt="Vavo"></a>')
    }
  })

  $('.nav-item').on('click', function () {
    if ($('.nav-item').hasClass('active')) {
      $('#nav').toggleClass('active')
      $(".nav-item").toggleClass('active')
      $(".line").toggleClass('active')
    }
    if ($('#nav').hasClass('active')) {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo.png" alt="Vavo"></a>')
    } else {
      $("#logo").html('<a href="#"><img src="./dist/img/logo/logo-black.png" alt="Vavo"></a>')
    }
  })

  // NEWSLETTER
  $('.newsletter').on('submit', function (e) {
    e.preventDefault();
    const DATA = $(this).serialize();

    $.ajax({
      type: 'POST',
      url: $(this).attr('action'),
      dataType: 'json',
      data: DATA,
      xhrFields: {
        withCredentials: false,
      },
      success: function (data) {
        $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
      },
      error: function (err) {
        console.log(err);
      }
    });
  });


  // $('#toggle-bio').on('click', function (e) {
  //   e.preventDefault();
  //   $('#bio').toggleClass('show-less')
  //   if ($('#bio').hasClass('show-less')) {
  //     $('#toggle-bio').text('READ MORE')
  //   } else {
  //     $('#toggle-bio').text('SHOW LESS');
  //   }
  // })

  // hide 'show more dates' button
  // if ($("#tour-dates").children().length < 9) {
  //   $("#toggle-dates").hide();
  // }

});